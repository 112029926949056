//Core
@import 'core/navbar.scss';
@import 'core/footer';

//Base
@import 'base/reset.scss';

// GENERAL
body {
  margin: 0 !important;
  background-color: #000;
  font-family: 'Montserrat', sans-serif;
  font-size: 18px;
  line-height: 1.7;
  color: #fff;
}

h1 {
  font-size: 56px;
  font-weight: 700;
  line-height: 1.2;
  text-transform: uppercase;
  margin-bottom: 80px;
}

h2 {
  font-size: 48px;
  line-height: 56px;
  font-weight: 700;
  margin-bottom: 40px;
}

h4 {
  font-size: 32px;
  line-height: 40px;
  font-weight: 600;
  margin-bottom: 24px;
}

h5 {
  font-size: 24px;
  line-height: 32px;
}

p {
  margin-bottom: 24px;
}

ul {
  margin-bottom: 40px;
}

li {
  line-height: 1.5;
  margin-bottom: 16px;
  padding-bottom: 16px;
  border-bottom: 1px solid #333;

  &:last-child {
    border-bottom: 0;
  }

  i {
    margin-right: 16px;
  }
}

img {
  margin-bottom: 24px;
}

a,
button {
  text-decoration: none;
  color: #fff;
}

// GLOBALS
.navbar {
  width: 100%;
  max-width: 1080px;
  margin: 0 auto;
  padding: 0 16px;
  background-color: #000;
}

.navbar-nav {
  flex-grow: 1;
  margin: 0 -24px;

  &:last-child {
    justify-content: right;
    -webkit-justify-content: flex-end;
    align-items: flex-end;
    -webkit-align-items: flex-end;
  }
}

.nav-item {
  border-bottom: 0;
  padding-bottom: 0;
  margin-bottom: 0;
  margin: 0 16px;

  a {
    transition: color ease-out .3s;

    &:hover {
      color: #1ef782 !important;
      transition: color ease-in .3s;
    }

    .sr-only {
      color: #1ef782 !important;
    }
  }
}

.navbar-brand {
  margin-right: 0;

  .logo {
    max-width: 80px;
    width: 100%;
    margin-bottom: 0;
  }
}


.section {
  padding: 96px 0;

  &__green {
    background: #141e1d;
  }
}

@media screen and (max-width: 962px) {
  .section {
    padding: 80px 0;
  }
  //tablet
}

@media screen and (max-width: 767px) {
  .section {
    padding: 40px 0;
  }

  //phone

}

.container {
  width: 100%;
  max-width: 1080px;
  padding: 0 16px;
  margin: auto;

  &__hero {
    padding-top: 0px;
  }
}

// BUTTONS
.btn {
  padding: 24px 48px;
  border-radius: 40px;
  font-weight: 600;
  transform: scale(1);
  transition: transform ease-out .2s;

  &:hover {
    transform: scale(1.05);
    transition: transform ease-in .2s;
  }

  &__primary {
    background-color: #1ef782;
    color: #fff;

    &:hover {
      color: #fff;
    }
  }

  &__secondary {
    border: 2px solid #1ef782;
    color: #1ef782;

    &:hover {
      color: #1ef782;
    }
  }

  i {
    margin-left: 16px;
  }
}

.item {
  margin-bottom: 80px;
}

// NFTS COLLECTION
.nfts {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: 40px;

  &__item {
    width: calc(25% - 24px);
    flex-grow: 1;

    img {
      width: 100%;
    }

    h5 {
      text-transform: uppercase;
      text-align: center;
    }
  }
}

@media screen and (max-width: 1023px) {
  .nfts {
    &__item {
      width: calc(33.333% - 16px);
    }
  }
}

@media screen and (max-width: 800px) {
  .nfts {
    &__item {
      width: calc(50% - 16px);
    }
  }
}

@media screen and (max-width: 480px) {
  .nfts {
    &__item {
      width: calc(100% - 0px);
    }
  }
}

// TIMELINE
.timeline {
  display: flex;
  justify-content: space-between;
  margin-top: 80px;

  &__item {
    width: calc(50% - 40px);

    &--img {
      position: relative;
      text-align: center;

      img {
        position: sticky;
        top: 140px;
      }
    }
  }

  .list {
    &__item {
      display: flex;
      margin-bottom: 40px;

      &--img {
        margin-right: 24px;
        position: relative;
      }

      .divider {
        position: absolute;
        top: 80px;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
        width: 1px;
        background: #fff;
      }
    }
  }
}

@media screen and (max-width: 960px) {
  .timeline {
    &__item {
      width: auto;

      &--img {
        img {
          max-width: 200px;
          padding: 24px;
        }
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .timeline {
    flex-direction: column;

    &__item {
      width: auto;

      &--img {
        display: none;

      }
    }
  }
}


// Unlock Page
.extension-login_noExtensionButtonTitle {
  color: #fff !important;
}

.btn-unlock {
  background: #1ef782 !important;
  padding: 24px 40px !important;
  border: 2px solid #1ef782 !important;
  margin-right: 16px;
}

@media screen and (max-width:767px) {
  .btn-unlock {
    margin-right: 0;
    margin-bottom: 4px;
  }
}

.btn-primary {
  background: transparent !important;
  border: 2px solid #1ef782 !important;
  padding: 24px 40px !important;
  color: #1ef782 !important;
}

.btn-primary,
.btn-unlock {
  min-width: 240px;
  text-align: center !important;
  font-weight: 600 !important;
  transform: scale(1) !important;
  transition: transform ease-out .2s !important;
}

.btn-primary,
.btn-unlock {
  &:hover {
    transform: scale(1.05) !important;
    transition: transform ease-in .2s !important;
  }
}

.video-heading{
  margin-top: 300px;
}
@media screen and (max-width: 767px){
  .video-heading{
    margin-top: 25px;
  }
}

.modal-content {
  color: black!important;
}

.hero-video-cls{
  width: 100%;
  height: auto;
}

.hero-video-container {
  width: 100%;
  height: 100%;
  top: 0;
  z-index: 999;
  background: rgba(0,0,0,0.6);
  max-width: 100%;
}

@media screen and (min-width: 767px) {
  .hero-video-container{
    position: absolute;
  }
}

.transactions-toast-list_toastFooter{
  color:black!important;
}

.transactions-toast-list_title{
  color:black!important;
}

#dapp-modal {
  z-index: 8000;
  div, button {
    background-color: #141e1d;
  }
  button {
    border: 1px white solid;
  }
  strong, svg {
    color: #1ef782;
  }
}

#failed-status-toast, .dapp-transaction-toast {
  background-color: #141e1d !important;
}