@import '../utils/utils.scss';

.footer {
  padding: 50px;
  background-color: #000;
  p {
    color: #aaaaaa;
    font-family: Montserrat;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: center;
  }
}
