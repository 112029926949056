@import '../utils/utils.scss';

.sticky-top {
  margin-bottom: -140px;
  padding: 16px 0;
}

.my_navbar {
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 1080px;
  margin: 0 auto;
  height: 80px;

  .navlink {
    &__logo {
      max-width: 100px;
    }

    &__name {
      font-family: 'Montserrat';
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 20px;
      text-transform: uppercase;
      padding: 32px;
      color: white;
      transition: color ease-out .3s;
    }

    :hover {
      color: #1ef782;
      transition: color ease-in .3s;
    }

    // &__active {
    //   color: #1ef782;
    // }
  }
}